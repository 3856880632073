import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d2e5fc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttonContent" }
const _hoisted_2 = {
  key: 0,
  class: "buttonIcon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["button", {'rightIcon' : _ctx.alignRight, 'darkmode' : _ctx.darkMode}]),
    ref: "buttonComp"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.faIcon.length > 0 || _ctx.faIconTwo.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.faIcon.length > 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(_ctx.faType+' fa-'+_ctx.faIcon+' firstIcon')
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.faIconTwo.length > 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: _normalizeClass(_ctx.faType+' fa-'+_ctx.faIconTwo+' secondIcon')
                }, null, 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.textLabel), 1)
    ])
  ], 2))
}