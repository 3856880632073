import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const ContactView = () => import('@/views/ContactView.vue');
const TeamPartnerView = () => import('@/views/TeamPartnerView.vue');
const NewsView = () => import('@/views/NewsView.vue');
const JobsView = () => import('@/views/JobsView.vue');
const EventsView = () => import('@/views/EventsView.vue');
const ApproachView = () => import('@/views/ApproachView.vue');
const HomeView = () => import('@/views/HomeView.vue');
const LegalView = () => import('@/views/collections/LegalView.vue');

const TeamDetailView = () => import('@/views/collections/TeamDetailView.vue');
const PartnerDetailView = () => import('@/views/collections/PartnerDetailView.vue');
const EventsDetailView = () => import('@/views/collections/EventsDetailView.vue');
const EventsCancelView = () => import('@/views/EventsCancelView.vue');
const NewsDetailView = () => import('@/views/collections/NewsDetailView.vue');


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/approach',
    name: 'ApproachView',
    component: ApproachView,
    props:{
      scrollTo: {type: String, default: ""}
    }
  },
  {
    path: '/events',
    name: 'EventsView',
    component: EventsView
  },
  {
      path: '/event/:url',
      name: 'EventDetailView',
      component: EventsDetailView,
      props: {
        url: {type:String, required: true}
      }
  },
  {
      path: '/event/cancel/:url',
      name: 'EventCancelView',
      component: EventsCancelView,
      props: {
        url: {type:String, required: true}
      }
  },
  {
    path: '/jobs',
    name: 'JobsView',
    component: JobsView
  },
  {
    path: '/news',
    name: 'NewsView',
    component: NewsView
  },
  {
      path: '/news/:url',
      name: 'NewsDetailView',
      component: NewsDetailView,
      props: {
        url: {type:String, required: true}
      }
  },
  {
    path: '/team',
    name: 'TeamPartnerView',
    component: TeamPartnerView
  },
  {
      path: '/team/:url',
      name: 'TeamDetailView',
      component: TeamDetailView,
      props: {
        url: {type:String, required: true}
      }
  },
  {
      path: '/partner/:url',
      name: 'PartnerDetailView',
      component: PartnerDetailView,
      props: {
        url: {type:String, required: true}
      }
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView,
    props: {
      reason: {type:String, default: ""}
    }
  },
  {
    path: '/legal/:url',
    name: 'LegalView',
    component: LegalView,
    props: {
      url: {type:String, required: true}
    }
  },
  {
    path: '/imprint',
    redirect: '/legal/imprint',
  },
  {
    path: '/privacy',
    redirect: '/legal/privacy',
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }else{
      return { top: 0 }
    }
  }
})

export default router
