
import {defineComponent, ref} from "vue";
import gsap from "gsap";
import {_cmsStore} from "@/stores/cmsStore";
export default defineComponent({
  name: "BaseButton",
  props: {
    faType: {type: String, default: 'fas'}, // far => regular | fab => business | fas => solid
    faIcon: {type: String, default: ''},
    btnLabel: {type: String, required: true},
    alignRight: {type: Boolean, default: false},
    darkMode: {type: Boolean, default: false},
    faIconTwo: {type: String, default: ''},
    btnLabelTwo: {type: String, required: false},
    alternativeFunc: {type: Function, required: false},
    clockReset: {type: Boolean, default: false}
  },
  watch:{
    btnLabel(){
      this.textLabel = this.$props.btnLabel;
    }
  },
  data(){
    return {
      modName: this.$options.name,
      twoStep: false,
      clicked: false,
      textLabel: this.$props.btnLabel,
      coolTimer: ref<ReturnType<typeof setTimeout>>()
    }
  },
  mounted() {
    if (
        (typeof (this.$props.faIconTwo) !== "undefined" && this.$props.faIconTwo.length > 0) ||
        (typeof (this.$props.btnLabelTwo) !== "undefined" && this.$props.btnLabelTwo.length > 0)
    ) {
      this.twoStep = true;
    }
  },
  activated() {
    // console.log(this.modName, "active");
  },
  deactivated() {
    // console.log(this.modName, "inactive");
  },
  methods: {
    passiveClick() : Boolean{
      if(!this.twoStep) return true;
      // when button is already in second State, choose to call alternative function on return true
      if(this.clicked){
        if(this.$props.clockReset) {
          // reset Timer to restart Counting from 0
          this.coolTimer = this.coolDown(true);
        }
        // run alternative Function on second Hit
        if(this.$props.alternativeFunc) this.$props.alternativeFunc();
        // dont continue to run first function
        return false;
      }
      this.clicked = true;
      // start Timer with 5 seconds and call this.resetButton after time has run out
      if(this.$props.clockReset) this.coolTimer = this.coolDown();
      const buttonDom = (this.$refs.buttonComp as HTMLDivElement);
      buttonDom.classList.add("stepTwo");
      // animate to Second State
      // Icon animation
      if(typeof (this.$props.faIconTwo) !== "undefined" && this.$props.faIconTwo.length > 0){
        this.animateIcon(buttonDom);
      }
      if(typeof (this.$props.btnLabelTwo) !== "undefined" && this.$props.btnLabelTwo.length > 0){
        this.animateText(buttonDom);
      }
      // Text animation
      return true; // Funktion im Parent element ausführen
    },
    coolDown(clearTimer: boolean = false) : ReturnType<typeof setTimeout>{
      if(clearTimer) clearTimeout(this.coolTimer);
      return window.setTimeout(this.resetButton, 5000);
    },
    resetButton(){
      if(!this.twoStep || !this.clicked) return;
      const buttonDom = (this.$refs.buttonComp as HTMLDivElement);
      if(typeof (this.$props.faIconTwo) !== "undefined" && this.$props.faIconTwo.length > 0) {
        console.log("animate Icon");
        this.animateIcon(buttonDom, false);
      }
      if(typeof (this.$props.btnLabelTwo) !== "undefined" && this.$props.btnLabelTwo.length > 0) {
        console.log("animate Text");
        this.animateText(buttonDom, false);
      }
      this.clicked = false;
      buttonDom.classList.remove("stepTwo");
    },
    animateIcon(dom: HTMLDivElement, dir:Boolean = true){
      // Animation mit GSAP einügen
      const iconGroup = ((dom.firstElementChild as HTMLDivElement).firstElementChild as HTMLDivElement).children;
      const iconSel = (dir) ? [iconGroup[0], iconGroup[1]] : [iconGroup[1], iconGroup[0]];
      const animSpeed = .2; // 20% animation speed of anim in speed
      // animate from First to Second Icon
      gsap.to(iconSel[0], {
        duration: (dir) ? 0.5 : 0.5*animSpeed,
        yPercent: (dir)?-150:150,
        ease: "power1.in",
        onComplete: () => {
          gsap.set(iconSel[0], {autoAlpha: 0});
          gsap.fromTo(iconSel[1], {
            scale: 0,
            autoAlpha: 1,
            yPercent: 0
          },{
            duration: (dir) ? 1 : 1*animSpeed,
            scale: 1,
            ease: "elastic.out(0.8, 0.3)"
          });
        }
      });
    },
    animateText(dom:HTMLDivElement, dir:Boolean = true){
      const labelDom = (dom.firstElementChild as HTMLDivElement).lastElementChild as HTMLSpanElement;
      const animSpeed = .2; // 20% animation speed of anim in speed
      gsap.to(labelDom, {
        yPercent: (dir)?-150:150,
        duration: (dir) ? 0.5 : 0.5*animSpeed,
        ease: "power1.in",
        onComplete: () => {
          this.textLabel = (dir && this.$props.btnLabelTwo) ? this.$props.btnLabelTwo : this.$props.btnLabel;
          gsap.fromTo(labelDom, {
            yPercent: (dir)?150:-150
          },{
            duration: (dir) ? 1 : 1*animSpeed,
            yPercent: 0,
            ease: "elastic.out(0.5, 0.3)"
          });
        }
      });
    },
  }
})
