import {reactive, readonly} from 'vue';

export abstract class Store<T extends Object> {
    protected state: T;

    constructor() {
        const data = this.data();
        this.setup(data);
        this.state = reactive(data) as T;
    }

    protected abstract data(): T

    protected setup(data: T): void {
        console.log("Store Setup Done");
    }

    public getData(): T {
        return readonly(this.state) as T
    }

    // handle Data Change Events
    private EventListHandler: Array<() => void> = [];

    // add any function to Event-Handler List
    public addListener(run: () => void){
        this.EventListHandler.push(run)
    }

    // Can be called on child-classes to trigger Event-Functions
    protected runListener(){
        for(var x in this.EventListHandler){
            this.EventListHandler[x]();
        }
    }

}