
import {defineComponent} from "vue";
import {_cmsStore} from "@/stores/cmsStore";
import pageCMS from "@/modules/cmsRest";

interface IOffices {
  title: string,
  location: string
}

interface linkIcon {
  icon:  string
  title :  string
  url: string
}

interface legalLink{
  url: string,
  title: string
}

export default defineComponent({
  name: "FooterMod",
  components: {},
  emits: ["openCookies"],
  data() {
    return{
      office: [] as Array<IOffices>,
      slogan : "",
      conduct: {} as legalLink,
      socialIcons: [] as Array<linkIcon>,
      legalLinks: [] as Array<legalLink>
    }
  },
  mounted(){
    this.requestData();
  },
  methods: {
    openMail(encryptStr: string){
      const iconomyMail = atob(encryptStr);
      location.href = "mailto:iconomy <"+iconomyMail+">";
    },
    async requestData(){
      const cms = await _cmsStore.instance();
      cms.request("iconomy_footer", false, {})
      .then((response) => {
        this.structData(response, cms);
      }).catch((err) => {console.log(err);});
    },
    async requestLegal(){
      const cms = await _cmsStore.instance();
      cms.request("iconomy_legal", true, {
        select: ["url", "page_title"]
      })
      .then((response) => {
        this.structLegal(response.entries);
      }).catch((err) => {console.log(err)});
    },
    structData(cmsData:any, cmsClass: pageCMS){
      this.office = cmsClass.getArray(cmsData.offices) as Array<IOffices>;

      // ICONS
      if(cmsData.socialIcon.mail && cmsData.socialIcon.mail.length > 0){
        this.socialIcons.push({
          icon: "fas fa-envelope",
          title : "Open mail programm",
          url: btoa(cmsData.socialIcon.mail)
        } as linkIcon);
      }

      if(cmsData.socialIcon.tw && cmsData.socialIcon.tw.length > 0){
        this.socialIcons.push({
          icon: "fab fa-twitter",
          title : "Open twitter page",
          url: cmsData.socialIcon.tw
        } as linkIcon)
      }

      if(cmsData.socialIcon.fb && cmsData.socialIcon.fb.length > 0){
        this.socialIcons.push({
          icon: "fab fa-facebook",
          title : "Open facebook page",
          url: cmsData.socialIcon.fb
        } as linkIcon)
      }

      if(cmsData.socialIcon.inD && cmsData.socialIcon.inD.length > 0){
        this.socialIcons.push({
          icon: "fab fa-linkedin",
          title : "Open linkedIn profile",
          url: cmsData.socialIcon.inD
        } as linkIcon)
      }

      // SLOGAN
      if(cmsData.slogan.length > 0){
        const splitSlogan = cmsData.slogan.split("\n");
        let fullSlogan = "";
        for(var x = 0; x < splitSlogan.length; x++){
          if((x+1)%2 === 0) fullSlogan+= "<span>"+splitSlogan[x]+"</span>";
          else fullSlogan += splitSlogan[x];
        }
        this.slogan = fullSlogan;
      }

      this.requestLegal();

    },
    structLegal(cmsData:any){
      for(let x in cmsData){
        if(cmsData[x]._id === "6367f425396661244c000305") this.conduct = {
          title:cmsData[x].page_title, url:"/legal/"+cmsData[x].url
        };
        else this.legalLinks.push({
          title: cmsData[x].page_title,
          url: "/legal/"+cmsData[x].url
        });
      }
    }
  }
});
