import {inject} from "vue";

export enum modStateInfo{
    created,
    active,
    inactive
}

export enum viewStateInfo{
    loaded,
    leave,
    enter,
    hide
}


class dataLog{

    debugView = false;
    debugMod = false;
    debugData = false;

    mod(data: any, type: modStateInfo){
        if(!this.debugMod) return;
        let styleString = "font-size: 8px; font-weight: light; color: ";
        switch(type){
            case 0: styleString+= "yellow"; break;
            case 1: styleString+= "green"; break;
            case 2: styleString+= "red"; break;
        }

        console.log("%c"+data+" "+modStateInfo[type], styleString);
    }

    view(data: any, type: viewStateInfo){
        if(!this.debugView) return;
        let styleString = "font-size: 14px; font-weight: bold; color: ";
        switch(type){
            case 0: styleString+= "yellow"; break;
            case 1: styleString+= "orange"; break;
            case 2: styleString+= "green"; break;
            case 3: styleString+= "red"; break;
        }
        console.log("%c"+data+" "+viewStateInfo[type], styleString);
    }

    data(title: string, data: any){
        if(!this.debugData) return;
        console.log("%c"+title, "font-size: 14px; font-weight: light; color: green");
        const objParse = JSON.parse(JSON.stringify(data, null, 4));
        if(objParse.length > 1) console.debug(objParse);
        else console.log(data);
    }
}
export default new dataLog();