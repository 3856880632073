
import {defineComponent} from "vue";
import dataLog, {modStateInfo} from "@/modules/dataLog";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "Demo",
  components: {BaseButton},
  props: {
    demoProp: {type: Boolean, required: false}
  },
  data() {
    return {
      modName: this.$options.name,
      viewState: 1, // show banner (1) - settings (2) - hide(0)

      gtmProperty: "GTM-TCCF4NL",
      options:[
        {
          name: "Essential",
          text: "Marketing cookies are used to follow visitors on websites. The intention is to show ads that are relevant and appealing to the individual user and therefore more valuable to publishers and advertising third parties.",
          required: true,
          active: true
        },
        {
          name: "Analytics",
          text: "On our company website, we use analytics cookies to gather information about how visitors use our site. This helps us to improve the user experience and understand which areas of the site are most popular. If you prefer, you can choose to disable these cookies by adjusting your browser settings. However, please note that this may affect the functionality of our website and your experience while using it.",
          required: false,
          active: false
        },
        /*{
          name: "Marketing",
          text: "Marketing cookies are used to follow visitors on websites. The intention is to show ads that are relevant and appealing to the individual user and therefore more valuable to publishers and advertising third parties.",
          required: false,
          active: true
        }*/
      ] as Array<any>
    }
  },
  mounted() {
    dataLog.mod(this.modName, modStateInfo.created);

    const cookieString = window.localStorage.getItem("cookieStore");

    if(cookieString !== null && cookieString.length > 1){
      // load Settings from cookieString
      const cookieSettings=cookieString.split("-");

      for(var x in cookieSettings){
        const cookieSetting = cookieSettings[x].split("=");
        this.updateSetting(cookieSetting[0], (cookieSetting[1] === "1"));
      }
      console.groupEnd();

      this.viewState = 0;
    }

    console.group("Cookie settings:");
    for(var x in this.options){
      const currCookie = this.options[x] as any;
      console.log(currCookie.name, currCookie.active);
    }
    console.groupEnd();

    this.applyCookieRule();
  },
  activated() {
    dataLog.mod(this.modName, modStateInfo.active);
  },
  deactivated() {
    dataLog.mod(this.modName, modStateInfo.inactive);
  },
  methods: {
    applyCookieRule(){
      for(var x in this.options){
      // enforce cookie policy´
        var loopItm = this.options[x] as any;
        switch(loopItm.name) {
          case "Analytics":
            if(loopItm.active) {
              // add Google Tag Manager to Script header
              this.addToHeader();
            }else{
              this.remFromHeader();
              // delete cookies
              const removedCookies = this.delete_cookies("_ga");
              if(removedCookies){
                window.location.reload();
                console.log('Cookies cleaned - reloading');
              }
            }
            break;
        }
      }
    },
    delete_cookies(name : string) : boolean {
      var expires = new Date(0).toUTCString();
      var domain = location.hostname.replace(/^www\./i, "");

      var allCookies = document.cookie.split(";");
      var removedCookies = false;
      for(var x in allCookies){
        const currCookie = (allCookies[x].split("=") as Array<string>);
        const fullCookieName = currCookie[0].trim();
        const preCut = fullCookieName.substring(0, name.length);
        if(preCut === name){
          document.cookie = fullCookieName + "=; expires=" + expires + "; path=/; domain=." + domain;
          console.log("cookie '"+fullCookieName+"' gelöscht");
          removedCookies = true;
        }
      }
      return removedCookies;

    },

    addToHeader(){
      if(document.getElementById('gaScript')) return;

      var gaScript = document.createElement('script');
      gaScript.id = 'gaScript';

      gaScript.innerHTML =  "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': "  +
                              "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
                              "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
                              "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
                            "})(window,document,'script','dataLayer','"+this.gtmProperty+"')";

      document.head.append(gaScript);
    },
    remFromHeader(){
      if(!document.getElementById('gaScript')) return;
      var script = document.getElementById('gaScript') as HTMLScriptElement;
      document.head.removeChild(script);
    },
    openSettings(){
      this.viewState = 2;
    },
    refuseAll(){
      for(var x in this.options){
        if(this.options[x].required) continue;
        this.options[x].active = false;
      }
      this.viewState = 0;
      this.toLocalStore();
    },
    acceptAll(){

      for(var x in this.options){
        if(this.options[x].required) continue;
        this.options[x].active = true;
      }

      this.viewState = 0;
      this.toLocalStore();

    },
    saveSettings(){
      this.viewState = 0;
      this.toLocalStore();
    },
    toLocalStore(){
      let cookieArr = [];
      for(var x in this.options){
        cookieArr.push(this.options[x].name +"="+(this.options[x].active?1:0));
      }
      window.localStorage.setItem("cookieStore", cookieArr.join("-"));

      // Apply Setting
      this.applyCookieRule();
    },
    updateSetting(name: string, active: boolean){
      for(var x in this.options){
        if(this.options[x].name === name) this.options[x].active = active;
      }
    },
    toggle(type: number){
      if(!this.options[type] || this.options[type].required) return

      this.options[type].active = !this.options[type].active
    }
  }
});
