
import {defineComponent, ref} from "vue";
import gsap from "gsap";

export default defineComponent({
  name: "NavMod",
  props:{
    isHomepage: {type:Boolean, default: true},
    isLegal: {type:Boolean, default: false},
    blendIn: {type:Boolean, default: true}
  },
  watch:{
    isHomepage(){
      if(!this.isMobile) this.changeRoute();
    },
    blendIn(){
      this.resize()
      const Nav = this.$refs.navigationMod;
      gsap.fromTo(Nav as HTMLDivElement, {
        autoAlpha: 0,
        yPercent: -100
      },{
        autoAlpha: 1,
        yPercent: 0,
        ease: "power1.out",
        duration: .3
      });
    }
  },
  components: {},
  data() {
    return{
      scrollTop: true,
      isMobile: false,
      mobileOpen: false,
      scrollBar: ref<HTMLDivElement>()
    }
  },
  mounted(){
    window.addEventListener("resize", this.resize);
    window.addEventListener("scroll", this.animateScrollOffset);

    this.scrollBar = this.$refs.scrollPurcBar as HTMLDivElement;
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.animateScrollOffset);
  },
  methods: {
    changeRoute(){
      this.animateLinkOffset();
      this.animateScrollOffset();
    },
    resize(){
      // check for Screen Width
      const screenWidth = document.documentElement.offsetWidth;
      if(screenWidth < 850) {
        if(!this.isMobile){
          this.isMobile = true;
        }
      }else{
        if(this.isMobile) {
          this.isMobile = false;
        }

      }

      if(!this.isMobile) this.animateLinkOffset();
      else this.setMenuHeight();

    },
    animateLinkOffset(){
      const navLinks = this.$refs.linkBox as HTMLDivElement;
      if(!navLinks) return;

      if(this.$props.isHomepage){
        // animate NavLinks Center
        if(this.scrollTop) this.moveLinks(true);

      }else{
        this.moveLinks(false);
      }
    },
    animateScrollOffset(){

      const doc = document.documentElement;
      const topScroll = Math.floor(window.pageYOffset || doc.scrollTop);

      const triggerPoint = (this.isHomepage) ? window.innerHeight / 2 : 5;

      if(this.scrollTop && topScroll > triggerPoint){
        this.scrollTop = false;
        // animate NavLinks Back to Origin
        if(this.$props.isHomepage){
          this.moveLinks(false);
        }

      } else if(!this.scrollTop && topScroll < triggerPoint - 2){
        this.scrollTop = true;

        if(this.$props.isHomepage) {
          this.moveLinks(true);
        }
      }

      // if(!this.scrollBar) return;
      //
      // const scrollPurc = (topScroll / (document.documentElement.scrollHeight - window.innerHeight));
      // gsap.set(this.scrollBar, {width: (scrollPurc*100).toFixed(2)+"%"});
    },
    moveLinks(mid:boolean){
      if(this.isMobile) return;

      const navLinks = this.$refs.linkBox as HTMLDivElement;

      if(mid){
        // animate NavLinks to center
        const diff = ((navLinks.parentNode as HTMLDivElement).clientWidth - navLinks.clientWidth)/2;
        gsap.to(navLinks, {x:-diff,ease:"power1.out", duration: 0.25, delay:0.15});

        // blend Out logo
        gsap.to(navLinks.previousElementSibling, {
          autoAlpha:0,
          scale:.3,
          duration:0.5, ease:"power1.out",
          overwrite: true
        });
      }else{
        // animate NavLinks Back to Origin
        gsap.to(navLinks, {x:0,ease:"power1.out", duration: 0.25, delay: 0.15});

        // blend in Logo
        gsap.to(navLinks.previousElementSibling, {
          autoAlpha:1,
          scale:1,
          duration:1 ,ease:"back.out(3)",
          overwrite: true
        });
      }
    },

    // Mobile
    openMenu(){
      // set Overlay heigths
      this.setMenuHeight();

      this.mobileOpen = !this.mobileOpen;

      if(this.mobileOpen){
        // animate Text in
        const navLinkBox = this.$refs.menuLinkBox as HTMLDivElement;
        gsap.fromTo(navLinkBox.children, {
          autoAlpha: 0,
          y: 50,
          scale: 0.8
        },{
          autoAlpha:1,y:0,scale:1,
          duration: 0.3, stagger: 0.1, ease:"power1.out", delay: 0.2, overwrite: true
        });
      }

    },
    closeMenu(){
      if(this.isMobile && this.mobileOpen) this.openMenu();
    },
    setMenuHeight(){
      const overlay = this.$refs.linkView as HTMLDivElement;
      const innerHeight = window.innerHeight;
      gsap.set(overlay, {height: innerHeight});
    }
  }
});
