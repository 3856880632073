import apiClient from "@/modules/restClient";
import restClient, {basePath, bearer}  from "@/modules/restClient";

export interface cmsSetting {
    search?: Array<cmsSearch>,
    AndOrCondition? : string,
    limit? : number,
    offset? : number,
    select? : Array<string>,
    sort? : cmsSort
}

export interface cmsSort {
    name: string,
    order : boolean
}

export interface cmsSearch {
    data: param,
    negative?: boolean
}

export interface param {
    name : string,
    value : string
}

class pageCMS {

    imgStyles : any;

    ready: boolean = false;

    promReturn : any;

    async init() : Promise<boolean>{
        this.imgStyles = {};

        if(!this.promReturn) {
            // create new Request
            console.log('Initilizing CMS class');
            this.promReturn = this.loadPre();
        }else{
            // return already loading
            console.log('Waiting for CMS class to initialize');
        }

        return await this.promReturn;
    }

    loadPre() : Promise<boolean>{
        return new Promise((resolve, reject) => {
            if(this.ready) resolve(true)

            const styleURL = 'https://code.5ga.de:443/cms/imgstyle.json';
            fetch(styleURL)
                .then((res: any) => {
                    if(res.ok) return res.json();
                })
                .then((data:any) => {
                    this.imgStyles = data;
                    this.ready = true;
                    console.log("Done with Loading");
                    resolve(true);
                })
                .catch((err: any) => {
                    console.log('Fehler',err);
                    reject(false);
                })
        });
    }

    // wait for init to finish before returning the instance
    async idleInit() {

    }

    request(call : string, collection: boolean = false, settings : cmsSetting): Promise<any> {
        return new Promise(function(resolve, reject){

            let params : Array<param> = [];
            params.push({name: "populate", value: "3"});
            params.push({name: "lang", value: "de"});


            if(!settings.limit) settings.limit = 0;
            else params.push({name: "limit", value: settings.limit.toString()});

            if(!settings.offset) settings.offset = 0;
            else params.push({name: "skip", value: settings.offset.toString()});

            if(!settings.select) settings.select = [];
            else{
                for(var n in settings.select){
                    params.push({name:"fields["+settings.select[n]+"]", value: "1"});
                }
            }

            if(!settings.sort) params.push({name: "sort[_o]", value: "1"});
            else params.push({
                name: "sort["+settings.sort.name+"]",
                value: (settings.sort.order)? "1":"-1"
            });

            if(!settings.AndOrCondition) settings.AndOrCondition = "and";
            if(!settings.search) settings.search = [];
            else{
                for(var n in settings.search){
                    const searchElm: cmsSearch = settings.search[n];
                    const filterParam: param = searchElm.data;
                    let query = 'filter';
                    if(!searchElm.negative && settings.search.length > 1) query += '[$'+settings.AndOrCondition+'][]';
                    query += '['+filterParam.name+']';
                    if(searchElm.negative) query += '[$not]';

                    params.push({name:query, value:filterParam.value});
                }
            }

            let data = [];
            for(var n in params){
                data.push( params[n].name + "=" + encodeURI(params[n].value) );
            }

            const baseURL = (collection) ? 'collections' : 'singletons';
            apiClient.post("/"+baseURL+"/get/"+call, data.join('&'))
                .then((res:any) => {
                    // check if everything is allright
                    resolve(res.data);
                }).catch((err: any) => {
                reject(err);
            })
        });
    }

    image(imgData: any, imgFormat = 'default'){

        if(!imgData) return "";

        if(!(imgData.path)){
            console.log("Image Path or Image missing");
            return "";
        }

        if(!this.imgStyles) {
            console.log("Styles not defined");
            return imgData.path;
        }

        const imgPath = (imgData.path.substring(0,1) === "/") ? imgData.path.substring(1) : imgData.path;

        const property = [
            {name: "token", value: bearer },
            {name: "src", value: imgPath },
            {name: "o", value: true },
            {name: "q", value: 85 }
        ];

        const selStyle = (!this.imgStyles[imgFormat]) ? this.imgStyles.default : this.imgStyles[imgFormat];

        for(let n in selStyle){
            property.push(
                {name: (n).toString(), value: (selStyle[n]).toString()} as param
            )
        }

        const query_arr: Array<string> = [];
        for(let key in property){
            query_arr.push(property[key].name + '=' + encodeURI(property[key].value));
        }

        const imgAPI = '/api/cockpit/image?'+query_arr.join('&');
        return basePath + imgAPI;
    }

    getArray(objData: any[], subParam:string = "value"): Array<any>{
        if(typeof objData === "string") return [objData];

        let clean_array = [];

        if(objData && objData.length > 0) {
            for (let ind in objData) {
                clean_array.push(objData[ind][subParam]);
            }
        }

        return clean_array;
    }

    getAsset(assData: any){

        const fullPath = (typeof (assData) === "string") ? assData : assData.path;
        let path = (fullPath.substring(0, 1) === '/') ? fullPath.substring(1) : fullPath;

        const searchTerm = 'storage/uploads/';
        const isInStorage = path.search(searchTerm);

        if(isInStorage !== -1)  path = path.substring(isInStorage+searchTerm.length);

        return basePath+'/'+searchTerm+path;
    }

    stripHTML(htmlText: string): string {
        const newText = htmlText.replace(/(<([^>]+)>)/gi, "");
        const textBoxes = newText.split("\n");
        const newTextBox = textBoxes.filter((value) => {
            const cleanT = value.replace(/\xA0/g,' ');
            return (value !== "" && value !== " ");
        } );
        return newTextBox.join("<br>");
    }

    shortenText(htmlText:string, length:number) : string {
        const strippedText = this.stripHTML(htmlText);
        if(strippedText.length < length) return strippedText;

        // look for first point after char
        let firstP = strippedText.indexOf(". ", length);
        let firstBr = strippedText.indexOf("<br>", length);
        if(firstP < 0 && firstBr < 0) {
            // hard Break Text
            return strippedText.substring(0, length)+"...";
        }else if(firstP > -1 && firstBr > -1){
            // treffer fuer beide

            const smaln = (firstP < firstBr) ? firstP+1 : firstBr;
            return strippedText.substring(0, smaln);
        }

        const second = (firstP > firstBr) ? firstP+1 : firstBr;
        return strippedText.substring(0, second);

    }

}

export default pageCMS;