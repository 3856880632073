
import {defineComponent, ref} from "vue";
import FooterMod from "@/components/TopLevelView/FooterMod.vue";
import NavMod from "@/components/TopLevelView/NavMod.vue";
import CookieView from "@/components/TopLevelView/CookieView.vue";
import {_cmsStore} from "@/stores/cmsStore";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default defineComponent({
  components: {NavMod, FooterMod, CookieView},
  data() {
    return {
      loadMod: ref<HTMLDivElement>(),
      loadTimeout : ref<ReturnType<typeof setTimeout>>(),
      onHome: -1,
      onLegal: -1,
      navBlend: true
    }
  },
  watch:{
    $route(){
      this.isHome()
    }
  },
  mounted(){
    this.loadMod = this.$refs.loadingScreen as HTMLDivElement;
    this.isHome();
  },
  methods: {
    isHome(){
      if(!this.$route.name) return;

      if(this.$route.name=== "HomeView") this.onHome = 1;
      else this.onHome = 0

      if(this.$route.name=== "LegalView") this.onLegal = 1;
      else this.onLegal = 0

    },
    showLoading(){
      // start Timeout to Show Loading after few ms

      const runningTrigger = ScrollTrigger.getAll();
      for (var x in runningTrigger){
        runningTrigger[x].kill();
      }
      this.loadTimeout = window.setTimeout(() => {
        this.animateIn();
        this.loadTimeout = undefined;
      }, 5);

    },
    animateIn(){
      if(!this.loadMod) return;

      gsap.set(this.loadMod,{
        autoAlpha: 1,
      });

      gsap.fromTo(this.loadMod.firstElementChild,{
        scale: 0.25,
        autoAlpha: 0,
      }, {
        scale: 1,
        autoAlpha: 1,
        ease: "power1.out",
        duration: .6,
        delay: .1
      });

      gsap.fromTo(this.loadMod.lastElementChild,{
        y: 100,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        ease: "power1.out",
        duration: .6,
        delay: .1
      });
    },
    hideLoading(){
      // blend in Navigation on first Load
      if(this.navBlend){
        this.navBlend = false;
      }


      if(!this.loadTimeout){
        // only hide Loadout when timeout is null
        this.animateOut();
      }else{
        // timeout is to be called
        // canceled Page Transition because new page was loaded within 10 ms
        window.clearTimeout(this.loadTimeout);
        this.loadTimeout = undefined;
      }

    },
    animateOut(){
      if(!this.loadMod) return;

      gsap.to(this.loadMod, {
        autoAlpha: 0,
        duration: 0.3,
        ease: "linear"
      });
    },
    openSettings(){
      (this.$refs.cookieManager as InstanceType<typeof CookieView>).viewState = 2;
    }
  }
});

