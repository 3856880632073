import {Store} from "./mainStore";
import pageCMS, {cmsSetting} from "@/modules/cmsRest";

class cmsStore extends Store<pageCMS> {
    protected data(): pageCMS {
        return new pageCMS();
    }

    async instance() : Promise<pageCMS>{
        try {
            if(this.state.ready) {
                return this.state;
            } else {
                await this.state.init();
                return this.state;
            }
        } catch (err) {
            console.log(err);
            return this.state;
        }
    }
}

export const _cmsStore: cmsStore = new cmsStore();